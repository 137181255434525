import { SystemInfoInterface } from '../utils/types/systemInfo';
import ApiService from './api';

export type UpdateSystemInfoDto = Omit<
  SystemInfoInterface,
  'id' | 'state' | 'created_by' | 'updated_by' | 'createdAt' | 'updatedAt'
>;

export default class SystemInfoService extends ApiService {
  list = async (searchTerms: string) => {
    const { data } = await this.axios.get('system-info', {
      params: {
        filterTerms: `vessel_id:${searchTerms}`
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`system-info/${id}`);

    return data;
  };

  update = async (id: string, systemInfo: UpdateSystemInfoDto) => {
    const { data } = await this.axios.put(`system-info/${id}`, systemInfo);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`system-info/${id}`);

    return data;
  };
}
