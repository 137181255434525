import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, useContext } from 'react';
import { ReactComponent as SystemInfoIcon } from '../../../assets/images/system-info-wrapper.svg';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { VesselContext } from '../../../context/vesselContext';
import TemplateBox from '../../form-templates/TemplateBox';
import SystemInfoForm from './SystemInfoForm';
import { useRef } from 'react';

const SystemInfoList: FC = () => {
  const systemInfoFormRef = useRef<{ handleSave: () => void }>(null);

  const { state: vesselState } = useContext(VesselContext);

  const handleSaveClick = () => {
    if (systemInfoFormRef.current) {
      systemInfoFormRef.current.handleSave();
    }
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb previous={vesselState.view.data?.name || 'Vessel'} current={'System Info'} />
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}>
        <TemplateBox height={'calc(100vh - 240px)'}>
          <>
            <Grid display={'flex'} justifyContent={'space-between'}>
              <TitleGrid icon={<SystemInfoIcon className="icon-title" />} title={'System Info'} />
              <Button variant="contained" onClick={handleSaveClick}>
                Save
              </Button>
            </Grid>

            <SystemInfoForm ref={systemInfoFormRef} />
          </>
        </TemplateBox>
      </Grid>
    </>
  );
};

export default SystemInfoList;
