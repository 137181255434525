import { ActionInterface, ErrorInterface } from '../../utils/types/common';
import { MyProfileAction, MyProfilesStateInterface } from '../../utils/types/myProfile';
import { UserBasicInfo } from '../../utils/types/user';
import MyProfileActionTypes from '../actions/types/myProfile';

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const myProfileInitialState: UserBasicInfo = {
  name: '',
  lastName: '',
  email: '',
  phone: '',
  createdAt: '',
  role: null
};

export const initialState: MyProfilesStateInterface = {
  viewInfo: { ...actionInitialState, data: myProfileInitialState },
  updateInfo: actionInitialState,
  updatePassword: actionInitialState
};

export const myProfileReducer = (
  state = initialState,
  action: MyProfileAction
): MyProfilesStateInterface => {
  switch (action.type) {
    case MyProfileActionTypes.VIEW_INFO_IN_PROGRESS:
      return {
        ...state,
        viewInfo: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case MyProfileActionTypes.VIEW_INFO_SUCCESS:
      return {
        ...state,
        viewInfo: {
          loading: false,
          error: undefined,
          done: true,
          data: action.payload as UserBasicInfo
        }
      };
    case MyProfileActionTypes.VIEW_INFO_ERROR:
      return {
        ...state,
        viewInfo: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case MyProfileActionTypes.UPDATE_INFO_IN_PROGRESS:
      return {
        ...state,
        updateInfo: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case MyProfileActionTypes.UPDATE_INFO_SUCCESS:
      return {
        ...state,
        updateInfo: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case MyProfileActionTypes.UPDATE_INFO_ERROR:
      return {
        ...state,
        updateInfo: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case MyProfileActionTypes.UPDATE_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        updatePassword: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case MyProfileActionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePassword: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case MyProfileActionTypes.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePassword: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case MyProfileActionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
