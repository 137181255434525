import paths from '../paths';
import { ReactComponent as AdminsIcon } from '../../../assets/images/user-edit.svg';
import { ReactComponent as CustomersIcon } from '../../../assets/images/brief-case.svg';
import { ReactComponent as VesselsIcon } from '../../../assets/images/ship.svg';
import { ReactComponent as BlogIcon } from '../../../assets/images/first-line-blog.svg';
import { ReactComponent as FormTemplatesIcon } from '../../../assets/images/form.svg';
import { ReactComponent as ChecklistIcon } from '../../../assets/images/check-list.svg';
import { ReactComponent as TankLevelsIcon } from '../../../assets/images/tank-levels.svg';
import { ReactComponent as SystemInfoIcon } from '../../../assets/images/system-info.svg';
import '../../../components/menu/Menu.scss';

export const menuList = [
  {
    name: 'Admins',
    icon: <AdminsIcon className="icon" />,
    path: paths.ADMINS.slug
  },
  {
    name: 'Customers',
    icon: <CustomersIcon className="icon" />,
    path: paths.CUSTOMERS.absolutePath
  },
  {
    name: 'Vessels',
    icon: <VesselsIcon className="icon" />,
    path: paths.VESSELS.slug
  },
  {
    name: 'Blog',
    icon: <BlogIcon className="icon" />,
    path: paths.BLOG_ENTRIES.slug
  }
];

export const nestedMenu = [
  {
    name: 'Form templates',
    icon: <FormTemplatesIcon className="icon" />,
    path: paths.PAGE_NOT_FOUND.slug
  },
  {
    name: 'Checklist',
    icon: <ChecklistIcon className="icon" />,
    path: paths.TEMPLATE_CHECKLIST.slug
  },
  {
    name: 'Tank levels',
    icon: <TankLevelsIcon className="icon" />,
    path: paths.TEMPLATE_TANK_LEVELS.slug
  }
];
