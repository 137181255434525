import SystemInfoService, { UpdateSystemInfoDto } from '../../services/systemInfo';
import SystemInfoActionsTypes from './types/systemInfo';
import { errorPayload } from './utils/errorPayload';

export const listSystemInfo = (dispatch: any) => {
  return async (searchTerms: string) => {
    dispatch({ type: SystemInfoActionsTypes.LIST_SYSTEM_INFO_IN_PROGRESS });

    try {
      const systemInfoService = new SystemInfoService();
      const systemInfoList = await systemInfoService.list(searchTerms);

      dispatch({ type: SystemInfoActionsTypes.LIST_SYSTEM_INFO_SUCCESS, payload: systemInfoList });

      return systemInfoList;
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: SystemInfoActionsTypes.LIST_SYSTEM_INFO_ERROR, payload });

      return [];
    }
  };
};

export const viewSystemInfo = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: SystemInfoActionsTypes.VIEW_SYSTEM_INFO_IN_PROGRESS });

    try {
      const systemInfoService = new SystemInfoService();
      const systemInfo = await systemInfoService.view(id);

      dispatch({ type: SystemInfoActionsTypes.VIEW_SYSTEM_INFO_SUCCESS, payload: systemInfo });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: SystemInfoActionsTypes.VIEW_SYSTEM_INFO_ERROR, payload });
    }
  };
};

export const updateSystemInfo = (dispatch: any) => {
  return async (id: string, dto: UpdateSystemInfoDto) => {
    dispatch({ type: SystemInfoActionsTypes.UPDATE_SYSTEM_INFO_IN_PROGRESS });

    try {
      const systemInfoService = new SystemInfoService();
      await systemInfoService.update(id, dto);

      dispatch({ type: SystemInfoActionsTypes.UPDATE_SYSTEM_INFO_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoActionsTypes.UPDATE_SYSTEM_INFO_ERROR, payload });
    }
  };
};

export const deleteSystemInfo = (dispatch: any) => {
  const systemInfoService = new SystemInfoService();

  return async (id: string) => {
    dispatch({ type: SystemInfoActionsTypes.DELETE_SYSTEM_INFO_IN_PROGRESS });

    try {
      await systemInfoService.delete(id);

      dispatch({ type: SystemInfoActionsTypes.DELETE_SYSTEM_INFO_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoActionsTypes.DELETE_SYSTEM_INFO_ERROR, payload });
    }
  };
};

export const resetSystemInfoState = (dispatch: any) => {
  return async () => dispatch({ type: SystemInfoActionsTypes.RESET_STATE });
};
