import { Divider, FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ChangeEvent, FC, PropsWithChildren, ReactElement } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/close-circle.svg';
import FieldContainerExternal from '../../components/field-container/FieldContainer';
import InputText from '../../components/form/InputText';

import './TemplateBoxTwoFields.scss';

type Item = {
  name: string;
  type: string;
};

interface Props {
  item: Item;
  showDeleteIcon: Boolean;
  validationErrors: Item;
  boxValues: string[];
  onDelete: () => void;
  onChangeFieldName: (value: string) => void;
  handleDropdownChange: (value: string) => void;
}

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={12} sm={12} md={12} lg={12} xl={12} />;
};

const EmptySpace: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid
      item
      xs={1}
      sm={1}
      md={0.3}
      lg={0.3}
      xl={0.3}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      {children}
    </Grid>
  );
};

type OnChangeEventType = ChangeEvent<HTMLInputElement>;

const TemplateBoxTwoFields: FC<Props> = ({
  item,
  showDeleteIcon,
  validationErrors,
  boxValues,
  onDelete,
  onChangeFieldName,
  handleDropdownChange
}) => {
  const inputs = (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} paddingRight={'15px'} marginBottom={0}>
        <InputText
          name={'name'}
          label={'Field name'}
          value={item.name}
          validationErrors={validationErrors?.name}
          onChange={(e: OnChangeEventType) => onChangeFieldName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={0}>
        <FormControl error={!!validationErrors?.type}>
          <FormLabel component="label" className={'regular'}>
            Field type
          </FormLabel>
          <Select
            value={item.type}
            onChange={(e) => handleDropdownChange(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}>
            {boxValues.map((box, index) => (
              <MenuItem key={index} value={box}>
                {box}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{validationErrors?.type}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );

  const action = (
    <>
      {showDeleteIcon ? (
        <EmptySpace>
          <div onClick={onDelete} className="delete-icon">
            <CloseIcon />
            <span className="delete-icon-text">Delete</span>
          </div>
        </EmptySpace>
      ) : (
        <>
          <EmptySpace />
        </>
      )}
    </>
  );

  return (
    <FieldContainer>
      <Grid container display={'flex'} justifyContent={'flex-end'} position={'relative'}>
        {inputs}
        {action}
      </Grid>
    </FieldContainer>
  );
};

export default TemplateBoxTwoFields;
