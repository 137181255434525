export const authPath = '/auth';
export const privatePath = '/management';

export default {
  PAGE_NOT_FOUND: {
    slug: '/page-not-found',
    absolutePath: '/page-not-found',
    header: ''
  },
  NOT_AUTHORIZED: {
    slug: '/not-authorized',
    absolutePath: '/not-authorized',
    header: ''
  },
  INTERNAL_SERVER_ERROR: {
    slug: '/internal-server-error',
    absolutePath: '/internal-server-error',
    header: ''
  },
  UNDER_MAINTENANCE: {
    slug: '/under-maintenance',
    absolutePath: '/under-maintenance',
    header: ''
  },

  REQUEST_PASSWORD_RESET: {
    slug: 'request-password-reset',
    absolutePath: `${authPath}/request-password-reset`,
    header: ''
  },
  PASSWORD_CHANGED: {
    slug: 'password-changed',
    absolutePath: `${authPath}/password-changed`,
    header: ''
  },
  INSTRUCTIONS_SENT: {
    slug: 'instructions-sent',
    absolutePath: `${authPath}/instructions-sent`,
    header: ''
  },
  SIGN_IN: {
    slug: 'sign-in',
    absolutePath: `${authPath}/sign-in`,
    header: ''
  },
  CONFIRM_SIGNUP: {
    slug: 'confirm-signup',
    absolutePath: `${authPath}/confirm-signup`,
    header: ''
  },
  REQUIRE_NEW_PASSWORD: {
    slug: 'require-new-password',
    absolutePath: `${authPath}/require-new-password`,
    header: ''
  },

  ACTIVITY_FEED: {
    slug: 'activity-feed',
    absolutePath: `${privatePath}/activity-feed`,
    header: 'welcome'
  },
  CUSTOMERS: {
    slug: 'customers',
    absolutePath: `${privatePath}/customers`,
    header: 'Customers management'
  },
  ADMINS: {
    slug: 'admins',
    absolutePath: `${privatePath}/admins`,
    header: 'Admins management'
  },
  CUSTOMER: {
    slug: 'customer/:id',
    absolutePath: `${privatePath}/customer/:id`,
    header: ''
  },
  VESSELS: {
    slug: 'vessels',
    absolutePath: `${privatePath}/vessels`,
    header: 'Vessels management'
  },
  VESSEL_SECTIONS: {
    slug: 'vessel/sections',
    absolutePath: `${privatePath}/vessel/sections`,
    header: 'back'
  },
  VESSEL: {
    slug: 'vessel/:id',
    absolutePath: `${privatePath}/vessel/:id`,
    header: ''
  },
  VESSEL_EDIT: {
    slug: 'vessel/edit',
    absolutePath: `${privatePath}/vessel/edit`,
    header: 'back'
  },
  VESSEL_CHECKLIST: {
    slug: 'vessel/checklist',
    absolutePath: `${privatePath}/vessel/checklist`,
    header: 'back'
  },
  VESSEL_CHECKLIST_EDIT: {
    slug: 'vessel/checklist/edit',
    absolutePath: `${privatePath}/vessel/checklist/edit`,
    header: 'back'
  },
  FEATURE_GROUPS: {
    slug: 'feature-groups',
    absolutePath: `${privatePath}/feature-groups`,
    header: ''
  },
  TEMPLATE_CHECKLIST: {
    slug: 'template/checklist',
    absolutePath: `${privatePath}/template/checklist`,
    header: 'Form templates'
  },
  TEMPLATE_CHECKLIST_FORM: {
    slug: 'template/checklist/form',
    absolutePath: `${privatePath}/template/checklist/form`,
    header: 'back'
  },
  TEMPLATE_TANK_LEVELS: {
    slug: 'template/tank-levels/list',
    absolutePath: `${privatePath}/template/tank-levels/list`,
    header: 'Form templates'
  },
  TEMPLATE_TANK_LEVELS_FORM: {
    slug: 'template/tank-levels/form',
    absolutePath: `${privatePath}/template/tank-levels/form`,
    header: 'back'
  },
  TEMPLATE_SYSTEM_INFO_FORM: {
    slug: 'template/system-info/form',
    absolutePath: `${privatePath}/template/system-info/form`,
    header: 'back'
  },
  TEMPLATE_UPCOMING_SERVICES_LIST: {
    slug: 'template/upcoming-services/list',
    absolutePath: `${privatePath}/template/upcoming-services/list`,
    header: 'Form templates'
  },
  TEMPLATE_UPCOMING_SERVICE_FORM: {
    slug: 'template/upcoming-service/form',
    absolutePath: `${privatePath}/template/upcoming-service/form`,
    header: 'back'
  },
  VESSEL_TANK_LEVELS: {
    slug: 'vessel/tank-levels',
    absolutePath: `${privatePath}/vessel/tank-levels`,
    header: 'back'
  },
  VESSEL_TANK_LEVEL_EDIT: {
    slug: 'vessel/tank-level/edit',
    absolutePath: `${privatePath}/vessel/tank-level/edit`,
    header: 'back'
  },
  VESSEL_USCG_SAFETY_LIST: {
    slug: 'vessel/uscg-safety',
    absolutePath: `${privatePath}/vessel/uscg-safety`,
    header: 'back'
  },
  VESSEL_SYSTEM_INFO_LIST: {
    slug: 'vessel/system-info',
    absolutePath: `${privatePath}/vessel/system-info`,
    header: 'back'
  },
  VESSEL_SYSTEM_INFO_EDIT: {
    slug: 'vessel/system-info/edit',
    absolutePath: `${privatePath}/vessel/system-info/edit`,
    header: 'back'
  },
  VESSEL_UPCOMING_SERVICES_LIST: {
    slug: 'vessel/upcoming-services',
    absolutePath: `${privatePath}/vessel/upcoming-services`,
    header: 'back'
  },
  VESSEL_UPCOMING_SERVICES_EDIT: {
    slug: 'vessel/upcoming-services/edit',
    absolutePath: `${privatePath}/vessel/upcoming-services/edit`,
    header: 'back'
  },
  VESSEL_SERVICE_RECORDS_LIST: {
    slug: 'vessel/service-records/list',
    absolutePath: `${privatePath}/vessel/service-records/list`,
    header: 'back'
  },
  VESSEL_DOCUMENTS_LIST: {
    slug: 'vessel/documents/list',
    absolutePath: `${privatePath}/vessel/documents/list`,
    header: 'back'
  },
  VESSEL_CHECKLIST_HISTORY_LIST: {
    slug: 'vessel/checklist/history',
    absolutePath: `${privatePath}/vessel/checklist/history`,
    header: 'back'
  },
  VESSEL_VESSEL_MEDIA_FILES_LIST: {
    slug: 'vessel/media/files/list',
    absolutePath: `${privatePath}/vessel/media/files/list`,
    header: 'back'
  },
  VESSEL_CREW_REQUESTS: {
    slug: 'vessel/crew-requests/list',
    absolutePath: `${privatePath}/vessel/crew-requests/list`,
    header: 'back'
  },
  SYSTEM_INFO: {
    slug: 'system-info',
    absolutePath: `${privatePath}/system-info`,
    header: ''
  },
  SERVICE_RECORDS: {
    slug: 'service-records',
    absolutePath: `${privatePath}/service-records`,
    header: ''
  },
  USCG_SAFETY: {
    slug: 'uscg',
    absolutePath: `${privatePath}/uscg`,
    header: ''
  },
  BLOG_ENTRIES: {
    slug: 'blog-entries',
    absolutePath: `${privatePath}/blog-entries`,
    header: 'Blog'
  },
  BLOG_ENTRY_FORM: {
    slug: 'blog-entry/form',
    absolutePath: `${privatePath}/blog-entry/form`,
    header: 'back'
  },
  UPCOMING_SERVICES: {
    slug: 'upcoming-services',
    absolutePath: `${privatePath}/upcoming-services`,
    header: ''
  },
  VESSEL_CHECKLIST_HISTORY: {
    slug: 'checklist-history',
    absolutePath: `${privatePath}/checklist-history`,
    header: 'Form templates'
  },
  EDIT_PROFILE: {
    slug: 'edit-profile',
    absolutePath: `${privatePath}/edit-profile`,
    header: 'Edit profile'
  }
};
