import { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, CircularProgress } from '@mui/material';
import { ReactComponent as ShipIcon } from '../../assets/images/wrapped-ship.svg';
import { ReactComponent as UpcomingServiceIcon } from '../../assets/images/clipboard-export-wrapper.svg';
import { ReactComponent as CheckListIcon } from '../../assets/images/check-list-wrapper.svg';
import { ReactComponent as TankLevelsIcon } from '../../assets/images/tank-levels-wrapper.svg';
import { ReactComponent as UscgSafetyIcon } from '../../assets/images/uscg-safety-wrapper.svg';
import { ReactComponent as SystemInfoIcon } from '../../assets/images/system-info-wrapper.svg';
import { ReactComponent as ServiceRecordsIcon } from '../../assets/images/service-records-wrapper.svg';
import { ReactComponent as DocumentsIcon } from '../../assets/images/menu-board-wrapper.svg';
import { ReactComponent as CheckListHistoryIcon } from '../../assets/images/clock-wrapper.svg';
import { ReactComponent as VesselMediaIcon } from '../../assets/images/landscape.svg';
import { ReactComponent as CrewsRequestIcon } from '../../assets/images/crew.svg';
import { Notification } from '../../pages/vessels/VesselSections';
import { RouteDefinition } from '../../utils/types/routes';
import paths from '../../utils/constants/paths';
import ServiceCard from './ServiceCard';

const adminRoles = ['admin', 'operator'];
export interface ServiceInterface {
  name: string;
  icon: ReactElement;
  path: RouteDefinition;
  notification?: {
    backgroundColor: string;
    color: string;
  };
  allowRoles?: string[];
}

const services: ServiceInterface[] = [
  {
    name: 'Vessel info',
    icon: <ShipIcon className="icon" />,
    path: paths.VESSEL_EDIT,
    allowRoles: adminRoles
  },
  {
    name: 'Checklist',
    icon: <CheckListIcon className="icon" />,
    path: paths.VESSEL_CHECKLIST,
    allowRoles: adminRoles,
    notification: {
      backgroundColor: '#FFAB00',
      color: '#FFF'
    }
  },
  {
    name: 'Tank levels',
    icon: <TankLevelsIcon className="icon" />,
    path: paths.VESSEL_TANK_LEVELS,
    allowRoles: adminRoles
  },
  {
    name: 'USCG Safety',
    icon: <UscgSafetyIcon className="icon" />,
    path: paths.VESSEL_USCG_SAFETY_LIST,
    allowRoles: adminRoles
  },
  {
    name: 'System info',
    icon: <SystemInfoIcon className="icon" />,
    path: paths.VESSEL_SYSTEM_INFO_LIST,
    allowRoles: adminRoles
  },
  {
    name: 'Service records',
    icon: <ServiceRecordsIcon className="icon" />,
    path: paths.VESSEL_SERVICE_RECORDS_LIST,
    allowRoles: ['admin']
  },
  {
    name: 'Documents',
    icon: <DocumentsIcon className="icon" />,
    path: paths.VESSEL_DOCUMENTS_LIST,
    allowRoles: ['admin']
  },
  {
    name: 'Checklist history',
    icon: <CheckListHistoryIcon className="icon" />,
    path: paths.VESSEL_CHECKLIST_HISTORY_LIST,
    allowRoles: adminRoles
  },
  {
    name: 'Upcoming service',
    icon: <UpcomingServiceIcon className="icon" />,
    path: paths.VESSEL_UPCOMING_SERVICES_LIST,
    allowRoles: adminRoles
  },
  {
    name: 'Vessel media',
    icon: <VesselMediaIcon className="icon" />,
    path: paths.VESSEL_VESSEL_MEDIA_FILES_LIST,
    allowRoles: adminRoles
  },
  {
    name: 'Crew member / Make ready request',
    icon: <CrewsRequestIcon className="icon" />,
    notification: {
      backgroundColor: '#FF5630',
      color: '#FFF'
    },
    path: paths.VESSEL_CREW_REQUESTS,
    allowRoles: adminRoles
  }
];

interface ServiceCardListArgs {
  vesselId: string;
  notifications?: Notification[];
  role: string | null;
}

const ServiceCardList: FC<ServiceCardListArgs> = ({ vesselId, role }): ReactElement => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Estado de carga para esperar a que `role` esté definido
  const [permittedServices, setPermittedServices] = useState<ServiceInterface[]>([]);

  useEffect(() => {
    if (role) {
      const allowedServices = services.filter(
        (service) => !service.allowRoles || service.allowRoles.includes(role)
      );
      setPermittedServices(allowedServices);
      setLoading(false);
    }
  }, [role]);

  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  const onClick = (service: ServiceInterface) => {
    navigate({
      pathname: service.path.absolutePath,
      search: `vesselId=${vesselId}`
    });
  };

  if (loading) {
    return (
      <Box sx={loaderStyle}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Grid container>
      <Box sx={boxStyle}>
        {permittedServices.map((service: ServiceInterface, index: number) => (
          <ServiceCard key={index} service={service} onClickCard={() => onClick(service)} />
        ))}
      </Box>
    </Grid>
  );
};

export default ServiceCardList;

const loaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#E7E7E9'
};

const boxStyle = {
  backgroundColor: '#E7E7E9',
  justifyContent: 'flex-start',
  padding: '23px',
  gap: '23px',
  display: 'flex',
  flexWrap: 'wrap',
  '& > :not(style)': {
    width: 'auto',
    height: 'auto'
  }
};
