import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import TemplateBoxTwoFields from '../../form-templates/TemplateBoxTwoFields';
import {
  getSystemInfoFieldsType,
  SystemInfoFieldsTypes,
  SystemInfoInterface
} from '../../../utils/types/systemInfo';
import { SystemInfoTemplateItem } from '../../../utils/types/systemInfoTemplate';
import { Alert, Button, Grid } from '@mui/material';
import { systemInfoTemplateItemInitialState } from '../../../context/reducers/systemInfoTemplateReducer';
import { systemInfoTemplateFieldsSchema } from '../../form-templates/system-info/SystemInfoTemplateSchema';
import { validateFormArray } from '../../../utils/form';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { SystemInfoContext } from '../../../context/systemInfoContext';
import { VesselContext } from '../../../context/vesselContext';
import { VesselInterface } from '../../../utils/types/vessel';

const initialErrorValues = {
  name: '',
  fields: [{ label: '', field: '' }]
};

type ValidationErrors = {
  name: string;
  fields: { label: string; field: string }[];
};

type AlertType = {
  severity: 'success' | 'info' | 'warning' | 'error';
  text: string;
};

export const SystemInfoForm = forwardRef((props: any, ref) => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(initialErrorValues);
  const [template, setTemplate] = useState<SystemInfoTemplateItem[]>([]);
  const [NoFieldsError, setNoFieldsError] = useState(false);
  const { updateSystemInfo } = useContext(SystemInfoContext);
  const [alert, setAlert] = useState<AlertType | undefined>(undefined);
  const { listSystemInfo } = useContext(SystemInfoContext);
  const [systemInfo, setSystemInfo] = useState<SystemInfoInterface[]>([]);
  const { viewVessel, state: vesselState } = useContext(VesselContext);

  const [vessel, setVessel] = useState<VesselInterface | undefined>(undefined);

  useEffect(() => {
    const fetchSystemInfo = async () => {
      const data = await listSystemInfo(query.get('vesselId') || '');
      const results = data?.results || [];
      setSystemInfo(results);
      setTemplate(JSON.parse(results[0]?.template || '[]'));
    };

    fetchSystemInfo();
  }, []);

  useEffect(() => {
    if (vesselId) viewVessel(vesselId, { join: 'owner' });
  }, []);

  useEffect(() => {
    if (vesselState.view.data) setVessel(vesselState.view.data);
  }, [vesselState.view]);

  const query = useQueryParams();

  const vesselId = query.get('vesselId');

  const removeField = (index: number) => {
    const newFields = template.filter((_item, i) => i !== index);
    setTemplate(newFields);
  };

  const handleSave = async () => {
    if (template.length === 0) {
      setNoFieldsError(true);
      return;
    }

    const { errorSummary: fieldsErrors } = validateFormArray(
      systemInfoTemplateFieldsSchema,
      template.map((field) => ({
        label: field.label,
        field: field.field.type
      }))
    );

    setValidationErrors({
      name: validationErrors.name,
      fields: fieldsErrors
    });

    if (fieldsErrors.length === 0) {
      if (vesselId) {
        updateSystemInfo(systemInfo[0]?.id || '', {
          name: vessel?.name || '',
          template: JSON.stringify(template),
          vessel_id: vesselId
        });
        setAlert({
          severity: 'success',
          text: 'System info updated successfully'
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleSave
  }));

  const onChangeValues = (type: string, value: SystemInfoFieldsTypes, index: number) => {
    const newFieldsList = template.map((item, i) =>
      i === index
        ? {
            ...item,
            ...(type === 'name' ? { label: value } : { field: { type: value } })
          }
        : item
    );
    setTemplate(newFieldsList);
  };

  const addField = () => {
    const newFields = [...template, systemInfoTemplateItemInitialState];
    setTemplate(newFields);
    setNoFieldsError(false);
  };

  const renderFields = template?.map((item, i) => {
    const validationErrorField = validationErrors.fields[i] || { label: '', field: '' };
    return (
      <div key={i}>
        <TemplateBoxTwoFields
          showDeleteIcon={i !== 0}
          item={{
            name: item.label || '',
            type: item.field?.type || ''
          }}
          onDelete={() => removeField(i)}
          boxValues={getSystemInfoFieldsType()}
          validationErrors={{
            name: validationErrorField.label,
            type: validationErrorField.field
          }}
          onChangeFieldName={(e) => onChangeValues('name', e as SystemInfoFieldsTypes, i)}
          handleDropdownChange={(e) => onChangeValues('type', e as SystemInfoFieldsTypes, i)}
        />

        <div style={dividerStyle} />
      </div>
    );
  });

  return (
    <>
      {alert && (
        <Grid container sx={{ marginBottom: '11px' }}>
          <Grid item xs={12}>
            <Alert severity={alert.severity} onClose={() => setAlert(undefined)}>
              {alert.text}
            </Alert>
          </Grid>
        </Grid>
      )}
      {renderFields}
      {NoFieldsError && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <p style={{ color: 'red' }}>Please add at least one field.</p>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button
          variant="outlined"
          disabled={false}
          type="button"
          className="actionButton"
          onClick={addField}>
          Add field
        </Button>
      </Grid>
    </>
  );
});

export default SystemInfoForm;

const dividerStyle = { marginBottom: '24px', width: '100%' };
